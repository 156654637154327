/**
 * This module is used for the manfucaturer nav module. It is used on
 * /find-a-dealer/(manufacturer) page to show/hide dealer details and for
 * mobile view menu show/hide
 * Required dependencies: vertu-motors.js, cookie.js
 * @requires module:vm
 * @requires module:Cookie
 */
((window, document) => {
  // Store the cookie name used for the module
  const DEALER_COOKIE = 'vertuDlr';
  const BRAND_COOKIE = 'vertuBrand';
  const { Cookie } = window;
  const isFleetLocn = window.isFleetLocn ?? undefined;
  const bookAppointmentBtn = document.querySelector('.js-book-appointment');
  // Store all the valid franchises
  const validFranchises = [
    'audi',
    'citroen',
    'dacia',
    'ford',
    'honda',
    'hyundai',
    'infiniti',
    'jaguar',
    'jeep',
    'land-rover',
    'mazda',
    'mercedes-benz',
    'nissan',
    'peugeot',
    'renault',
    'seat',
    'skoda',
    'toyota',
    'vauxhall',
    'versa',
    'volkswagen',
    'volvo',
  ];

  /**
   * Set css style to a selector
   * @param {string} selector The selector of the element
   * @param {string} attribute The attribute to set on the style should be camelCase
   * @param {any} value The value to set the attribute
   */
  function setCssStyle(selector, attribute, value) {
    if (!selector || !attribute || !value) {
      window.vm.debug.error('There is no selector, attribute or value');
    }

    try {
      document.querySelector(selector).style[attribute] = value;
    } catch (err) {
      window.vm.debug.error(err);
    }
  }

  /**
   * Get the css style from a selector
   * @param {string} selector The selector of the element
   * @param {string} attribute The attribute to get from the style, should be camelCase
   */
  function getCssStyle(selector, attribute) {
    if (!selector || !attribute) {
      window.vm.debug.error('There is no selector or attribute value');
      return undefined;
    }

    try {
      return document.querySelector(selector).style[attribute];
    } catch (err) {
      window.vm.debug.error(err);
      return undefined;
    }
  }

  /**
   * Set the header z-index and secondary-nav z-index on tablet view
   */
  function setHeaderZIndex() {
    if (window.innerWidth <= 880) {
      if (getCssStyle('header', 'zIndex') === '101') {
        setCssStyle('header', 'zIndex', 'initial');
        setCssStyle('.secondary-nav', 'zIndex', '');
      } else {
        setCssStyle('header', 'zIndex', '101');
        setCssStyle('.secondary-nav', 'zIndex', '101');
      }
    }
  }

  /**
   * Modify the classList of the selector or element based on the provided method
   * It defaults to toggle method
   * @param {string | HTMLElement} selector The selector or html element
   * @param {string} className The class name to be used
   * @param {string} method The classList method to use
   * @returns {boolean | undefined} True if the class was added, false if the class was removed
   * in case of toggle method else returns undefined
   */
  function modifyElClass(selector, className, method = 'toggle') {
    if (className === '') {
      return false;
    }

    try {
      if (typeof selector === 'string') {
        selector = document.querySelector(selector);
      }
      if (selector !== null) {
        return selector.classList[method](className);
      }
      return undefined;
    } catch (err) {
      window.vm.debug.error(err);
      return false;
    }
  }

  /**
   * Handle the clicked events on the document
   * @param {MouseEvent} event The event that occured
   */
  function handleClickEvents(event) {
    const clickedEl = event.target;

    // Secondary nav dropdown trigger was clicked
    if (clickedEl.matches('.secondary-nav__dropdown-trigger')) {
      // Get the dropdown list from the parent container
      const dropdownList = clickedEl.parentElement.parentElement.querySelector(
        '.secondary-nav__dropdown-list',
      );
      if (dropdownList.textContent.trim() === '') {
        return;
      }

      if (modifyElClass(dropdownList, 'is-open')) {
        // If it is opened
        modifyElClass('.bg-overlay', 'is-open', 'add');

        // Close the secondary nav
        modifyElClass('.secondary-nav__items', 'is-open', 'remove');
        modifyElClass('.secondary-nav__trigger', 'is-active', 'remove');
      } else {
        // If it is closed
        modifyElClass('.bg-overlay', 'is-open', 'remove');
      }

      setHeaderZIndex();

      return;
    }

    // Secondary nav trigger was clicked
    if (clickedEl.matches('.secondary-nav__trigger')) {
      modifyElClass(clickedEl, 'is-active');
      if (modifyElClass('.secondary-nav__items', 'is-open')) {
        // If it is opened
        modifyElClass('.bg-overlay', 'is-open', 'add');

        // Close the secondary nav dropdown
        modifyElClass('.js-secondary-nav .secondary-nav__dropdown-list', 'is-open', 'remove');
      } else {
        // If it is closed
        modifyElClass('.bg-overlay', 'is-open', 'remove');
      }

      setHeaderZIndex();

      return;
    }

    // If secondary nav item was clicked
    if (clickedEl.matches('.secondary-nav__item')) {
      // Set cookie to display page name on mobile secondary nav
      const pageName = clickedEl.textContent;
      Cookie.set('vertuPageName', pageName);
    }
  }

  /**
   * Initialize the manufacturer nav
   */
  function initialize() {
    // Get the secondary nav
    const secondaryNav = document.querySelector('.js-secondary-nav');
    const currentPageSpan = document.querySelector('span.current-page');

    // TODO: Seach nav | Revisit this once the new nav have been fully implemented
    const siteContainer = document.querySelector('.js-site-container');

    // Check if we are on dealer landing page
    const isOnDealerLandingPage = /\/find-a-dealer\/(.+)/i.test(window.location.pathname);
    const isOnOurLocationsLandingPage = /^\/our-locations\/[a-z\-0-9]+\/$/i.test(
      window.location.pathname,
    );
    const isOnServicingPage = /servicing\//i.test(window.location.pathname);

    // Store the franchise value
    let franchiseValue;
    let dealerPathName = '/';
    let dealershipBrand;

    // Set the dealer name
    if (isOnDealerLandingPage || isOnOurLocationsLandingPage) {
      const pathNodes = window.location.pathname.split('/');
      dealerPathName = `/${pathNodes[1]}/${pathNodes[2]}/`;
      Cookie.set(DEALER_COOKIE, dealerPathName);
    } else {
      dealerPathName = Cookie.get(DEALER_COOKIE);
    }

    // Get the dlr cookie
    const dlrCookieValue = Cookie.get(DEALER_COOKIE);

    // if URL contains find-a-dealer, but is NOT the find a dealer
    // page itself (and it's NOT Toyota) show the secondary manufacturerNav
    if (isOnDealerLandingPage || isOnOurLocationsLandingPage) {
      if (secondaryNav) {
        secondaryNav.classList.remove('hide__default');
      }
    } else if (secondaryNav) {
      secondaryNav.classList.add('hide__default');
    }

    // Cookie was found
    if (typeof dlrCookieValue !== 'undefined' && dlrCookieValue !== '') {
      // test
      // Get the franchise name from the stored cookie url

      franchiseValue = dlrCookieValue.substring(15, dlrCookieValue.lastIndexOf('-'));

      if (franchiseValue.includes('vertu') && franchiseValue.includes('bmw')) {
        dealershipBrand = 'BMW';
      }

      // Some dealership locations have two word names (e.g. Hyundai Edinburgh West,
      // Nissan Glasgow South) so we end up with a franchise of "Hyundai Edinburgh"
      // Check if the franchise exists in the valid franchise
      if (validFranchises.indexOf(franchiseValue) < 0) {
        franchiseValue = franchiseValue.substring(0, franchiseValue.indexOf('-'));
      }

      // Capitalize the franchise name
      franchiseValue = franchiseValue.charAt(0).toUpperCase() + franchiseValue.substring(1);

      // Set the path name, need to check if this is needed
      window.locPathname = `/${franchiseValue.toLowerCase()}/`;

      // Add the franchise name to the js-site-container class to add the logo in the secondary nav
      if (!isOnServicingPage && (isOnDealerLandingPage || isOnOurLocationsLandingPage)) {
        modifyElClass(siteContainer, franchiseValue, 'add');
        modifyElClass(secondaryNav, franchiseValue, 'add');
      } else if (secondaryNav) {
        modifyElClass(secondaryNav, franchiseValue, 'add');
        modifyElClass(secondaryNav, dealershipBrand, 'add');
      }

      // Set the brand cookie in case that it is being used
      Cookie.set(BRAND_COOKIE, franchiseValue);
    }

    // Update the about us nav item href link
    const secondaryNavItems = document.querySelectorAll('.secondary-nav__item');
    for (let i = 0, len = secondaryNavItems.length; i < len; i += 1) {
      const navItem = secondaryNavItems[i];
      if (/about\sus/i.test(navItem.textContent) && dealerPathName !== '') {
        navItem.setAttribute('href', dealerPathName);
      }

      if (navItem.href === window.location.href) {
        modifyElClass(navItem, 'is-active', 'add');
      }
    }

    // If we are on the dealer landing page
    if (window.location.pathname === dealerPathName) {
      // Update cookie value
      Cookie.set('vertuPageName', 'About us');
      // Updating cookie after page load so need to change the page title
      try {
        if (currentPageSpan.textContent !== dealerPathName) {
          currentPageSpan.textContent = 'About us';
        }
      } catch (err) {
        window.vm.debug.error(err);
      }
    } else if (currentPageSpan) {
      currentPageSpan.textContent = Cookie.get('vertuPageName');
    }

    // If a bg-overlay is not found, add it to the page
    if (
      !document.querySelector('.bg-overlay') &&
      (document.querySelector('.footer--main') || document.querySelector('footer'))
    ) {
      (
        document.querySelector('.footer--main') || document.querySelector('footer')
      ).insertAdjacentHTML('afterend', '<div class="bg-overlay"></div>');
    }

    if (isFleetLocn && isFleetLocn.isGroupFleet === 'yes' && bookAppointmentBtn) {
      bookAppointmentBtn.classList.add('hide__default');
    }

    document.addEventListener('click', handleClickEvents);

    // Clear cookies when the primary nav is clicked
    try {
      if (
        document.querySelector('.js-primary-nav') ||
        document.querySelector('.js-site-primary-nav')
      ) {
        (
          document.querySelector('.js-primary-nav') ||
          document.querySelector('.js-site-primary-nav')
        ).addEventListener('click', () => {
          Cookie.delete(DEALER_COOKIE);
        });
      }
    } catch (err) {
      window.vm.debug.error(err);
    }
  }

  window.vm.onload(initialize);
})(window, document);
