((document) => {
  // Manufacturers that need to use png
  let pngManufacturers = [
    // 'dacia',
    'ds',
    'mazda',
    'landrover',
    'smart',
    // 'skoda',
    'bmw',
    // 'peugeot',
  ];

  // Test

  // There is a way to override default list by placing pngOVerride array with makes in it.
  pngManufacturers = document.pngOverride ? document.pngOverride : pngManufacturers;

  // Remove special characters
  const removeSpecCharacters = (manufacturer) => {
    let manuCopy = manufacturer.split('-').join('');
    manuCopy = manuCopy.split(' ').join('');
    return manuCopy;
  };

  // Capitalize first letter of the string
  const capitalizeFirstLetter = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

  const manuLogoHandler = (logos) => {
    logos.forEach((logo) => {
      const manufacturer = removeSpecCharacters(logo.dataset.manufacturer);
      const logoElement = logo.querySelector('.manufacturer__logo');

      try {
        if (pngManufacturers.indexOf(manufacturer.toLowerCase()) !== -1) {
          const manuImageElement = document.createElement('div');
          manuImageElement.classList.add(
            'manufacturer__image',
            capitalizeFirstLetter(manufacturer),
          );
          logoElement.replaceWith(manuImageElement);
        } else {
          logoElement.classList.add(capitalizeFirstLetter(manufacturer));
        }
      } catch (error) {
        vm.debug.log(error);
      }
    });
  };

  const initManuLogoHandler = () => {
    const logos = document.querySelectorAll('.js-logo-handler');

    if (logos.length > 0) {
      manuLogoHandler(logos);
    } else {
      vm.debug.log('There are no logos to replace');
    }
  };

  vm.onload(() => {
    initManuLogoHandler();
  });
})(document);
